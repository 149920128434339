enum GetPacketByIdResponseDataItemPacketStatusId {
  WAITING_FOR_DELIVERY = '1',
  READY_FOR_PICKUP = '2',
  ISSUED_AND_ACCOUNTED = '3',
  CANCELLED = '4',
  RETURNED_AND_ACCOUNTED = '5',
  RETURN = '20',
  LOST_OR_UNKNOWN = '21',
  CLOSED_BY_PROTOCOL = '22',
  SENT_BY_MAIL = '23',
  LONG_TERM_RETURN = '24',
  TO_BE_SENT_BY_MAIL = '25',
  PROBLEMATIC_CAPTURE = '30',
  ON_THE_WAY = '31',
  TO_BE_DISPOSED = '33',
  READY_LAST_DAY = '996',
  TO_BE_PROCESSED = '997',
  TO_BE_RETURNED = '998',
}

enum ApiHeaders {
  AUTHORIZATION = 'Authorization',
  ACCEPT_LANGUAGE = 'Accept-Language',
}

enum ApiRoutes {
  TRACKING = 'api2/tracking',
  EXTERNAL_TRACKING = 'api2/ex-track',
  CONTACT = 'api2/contact-track',
  PROLONG = 'api2/prolong',
  ADDRESS_REDIRECT = 'api2/address-redirect',
}

enum ApiStatusCodes {
  ID_MISSING = 'idMissing',
  ID_INVALID = 'idInvalid',
  LANGUAGE_MISSING = 'languageMissing',
  UNAUTHORIZED = 'unauthorized',
  FAILED = 'failed',
  FAILED_DECRYPT_TOKEN = 'failed-decrypt-token',
  NOT_FOUND = 'notFound',
  BARCODE_MISSING = 'barcodeMissing',
  DATE_MISSING = 'dateMissing',
}

enum ApiCacheCodes {
  CONTACT_TRACKING_DATA = 'contact-tracking-data',
  EXT_TRACKING_DATA = 'ext-tracking-data',
  TRACKING_DATA = 'tracking-data',
}

export {
  GetPacketByIdResponseDataItemPacketStatusId,
  ApiHeaders,
  ApiRoutes,
  ApiStatusCodes,
  ApiCacheCodes,
}
