const getFormattedShipmentNumber = (value: string) => {
  let formattedValue = value
  if (formattedValue.startsWith('Z')) {
    // Handle cases starting with 'Z'
    formattedValue = formattedValue.replace(/(?!^)Z/g, '') // Remove all 'Z's except the first one
    formattedValue = formattedValue
      .replace(
        /(Z)(\d{0,3})(\d{0,4})(\d{0,3})/,
        (match, p1, p2, p3, p4) =>
          `${p1}${p2.length ? ' ' + p2 : ''}${p3.length ? ' ' + p3 : ''}${p4.length ? ' ' + p4 : ''}`,
      )
      .substring(0, 14)
  } else if (formattedValue.length === 10) {
    // Handle the new case where the input is a 10-digit number without 'Z'
    formattedValue = `Z ${formattedValue
      .replace(/(\d{3})(\d{4})(\d{3})/, '$1 $2 $3')
      .substring(0, 12)}`
  } else if (formattedValue.length === 9) {
    // Handle the new case where the input is a 9-digit number without 'Z'
    formattedValue = `Z ${formattedValue
      .replace(/(\d{3})(\d{4})(\d{2})/, '$1 $2 $3')
      .substring(0, 13)}`
  } else if (formattedValue.length <= 8) {
    // Handle cases with up to 8 digits
    formattedValue = formattedValue.replace(/\D/g, '').substring(0, 8)
    formattedValue = formattedValue
      .replace(/(\d{0,4})(\d{0,4})/, (match, p1, p2) => `${p1}${p2.length ? ' ' + p2 : ''}`)
      .substring(0, 9)
  } else {
    // Handle all other cases
    formattedValue = formattedValue
      .replace(
        /(\d{0,3})(\d{0,4})(\d{0,3})/,
        (match, p1, p2, p3) =>
          `Z ${p1.length ? ' ' + p1 : ''}${p2.length ? ' ' + p2 : ''}${p3.length ? ' ' + p3 : ''}`,
      )
      .substring(0, 13)
  }

  return formattedValue
}

export { getFormattedShipmentNumber }
